
import { useEffect, useState } from "react";
import {
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import tecApi from "../../api/tecApi";
import { useAuthStore } from "../../hooks/useAuthStore";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { CustomSidebarHeader } from "./SidebarHeader";
import { CustomSidebarContent } from "./SidebarContent";
import { CustomSidebarFooter } from "./SidebarFooter";
export const Sidebar = ({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
  breakPoint,
  theme,
  changeTheme,
}) => {
  const [currentLocation, setCurrentLocation] = useState("");
  const [imagenUser, setImagenUser] = useState({});
  const { startLogout, user } = useAuthStore();
  const location = useLocation();

  useEffect(() => {
    tecApi.get("/auth/getUser/" + user.id).then((response) => {
      setImagenUser(response.data.user);
    });
  }, [user.id]);

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location]);
  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: "#222e3c",
          color: "#fff",
          fontFamily: "Inter",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 30px 5px 10px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#FFFFFF !important",
        },
        "& .pro-menu-item.active": {
          borderLeft: "solid 3px transparent",
          borderLeftColor: "#dc3545",
          background:
            "linear-gradient(90deg, rgba(59, 125, 221, 0.1),rgba(59, 125, 221, 0.088) 50%, hsla(0, 0%, 100%, 0))",
          color: "#dc3545 !important",
          fontWeight: 700,
          
        },
      }}
    >
      <ProSidebar
        image="https://i.pinimg.com/564x/d4/d2/67/d4d267560fae933744f18702a806fde9.jpg"
        collapsed={collapsed}
        toggled={toggled}
        onToggle={handleToggleSidebar}
        breakPoint={breakPoint}
      >
        <SidebarHeader>
          <CustomSidebarHeader
            collapsed={collapsed}
            handleCollapsedChange={handleCollapsedChange}
            imagenUser={imagenUser}
            user={user}
          />
        </SidebarHeader>
        <SidebarContent>
          <CustomSidebarContent user={user} currentLocation={currentLocation} />
        </SidebarContent>
        <SidebarFooter>
          <CustomSidebarFooter
            theme={theme}
            handleToggleTheme={changeTheme}
            startLogout={startLogout}
          />
        </SidebarFooter>
      </ProSidebar>
    </Box>
  );
};
