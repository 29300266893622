import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
const LoginPage = lazy(() => import("../pages/LoginPage"));
export const AuthRoute = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="/*" element={<Navigate to="/auth/login" />} />
      </Routes>
    </Suspense>
  );
};
