import { Avatar, Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "../../../../../theme/Theme";
import tecApi from "../../../../../api/tecApi";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import "moment/locale/es";
import LineChartModel from "./LineChartModel";
moment.locale("es");
const CountProducts = () => {
  let navigate = useNavigate();
  let { state } = useLocation();
  const { theme } = useTheme();
  const [values, setValues] = useState([]);
  useEffect(() => {
    tecApi
      .get("/reportProducts/reportProductsMonths", {
        params: { company: state?.company },
      })
      .then((response) => {
        setValues(response.data.countProducts);
      });
  }, []);
  const currentDate = moment();
  // Resta un mes a la fecha actual
  const previousTowMonthDate = currentDate
    .clone()
    .subtract(2, "month")
    .format("MMMM");
  const previousMonthDate = currentDate
    .clone()
    .subtract(1, "month")
    .format("MMMM");
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "white", // Cambia el color de los labels aquí
        },
      },
      title: {
        display: true,
        text: "CANTIDAD DE REPUESTOS UTILIZADOS",
        color: "white",
      },
    },
    scales: {
      x: {
        ticks: {
          color: "white",
          font: {
            size: 16,
          },
        },
      },
      y: {
        ticks: {
          color: "white",
          font: {
            size: 16,
          },
        },
      },
    },
  };
  const labels = [
    "El ALTO",
    "LA PAZ",
    "COCHABAMBA",
    "SANTA CRUZ",
    "ORURO",
    "POTOSI",
    "CHUQUISACA",
    "TARIJA",
    "BENI",
    "PANDO",
  ];
  const mappedDataFirstMonth = labels.map((dep) => {
    const dataPoint = (values.firstMonth || []).find(
      (data) => data._id === dep
    );
    return dataPoint ? dataPoint.count : 0;
  });
  const mappedDataSecondMonth = labels.map((dep) => {
    const dataPoint = (values.secondMonth || []).find(
      (data) => data._id === dep
    );
    return dataPoint ? dataPoint.count : 0;
  });
  const data = {
    labels,
    datasets: [
      {
        label: previousTowMonthDate,
        data: mappedDataFirstMonth,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: previousMonthDate,
        data: mappedDataSecondMonth,
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Box sx={{ display: "flex" }}>
          <Box>
            <Avatar
              variant="rounded"
              sx={{ width: 45, height: 45, bgcolor: "#f8f9fa" }}
              src={state?.companyImage}
            ></Avatar>
          </Box>
          <Box className={"content-title"}>
            <div className={`titleDatagrid ${theme}`}>Datos estadisticos</div>
            <div className={`subTitleTable ${theme}`}>
              Cantidad de productos utilizados en los dos meses anterior
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <button className={`buttonBack ${theme}`} onClick={goBack}>
          Regresar
        </button>
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Box className={`card ${theme}`}>
          <Bar options={options} data={data} />
        </Box>
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Box className={`card ${theme}`}>
          <LineChartModel company={state.company} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default CountProducts;
