import { createSlice } from "@reduxjs/toolkit";
export const ticketRequestSlice = createSlice({
  name: "ticketRequest",
  initialState: {
    isLoadingTicketRequest: true,
    listTicketRequest: [],
    activeRows: null,
  },
  reducers: {
    onSetActiveTicketRequest: (state, { payload }) => {
      state.activeRows = payload;
    },
    onAddRowTicketRequest: (state, { payload }) => {
      state.listTicketRequest.push(payload);
      state.activeRows = null;
    },
    onLoadTicketRequest: (state, { payload = [] }) => {
      state.isLoadingTicketRequest = false;
      state.listTicketRequest = payload;
    },
  },
});
export const {
  onSetActiveTicketRequest,
  onAddRowTicketRequest,
  onLoadTicketRequest,
} = ticketRequestSlice.actions;
