import React from "react";
import { Grid, Box, IconButton } from "@mui/material";
import { FcCancel, FcIdea, FcNightPortrait } from "react-icons/fc";

export const CustomSidebarFooter = ({
  theme,
  handleToggleTheme,
  startLogout,
}) => {
  const themeIcon = theme === "dark" ? <FcNightPortrait /> : <FcIdea />;

  return (
    <Grid container spacing={0}>
      <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
          }}
        >
          <label className="version">V1.1.0</label>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <IconButton onClick={handleToggleTheme}>{themeIcon}</IconButton>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <IconButton aria-label="delete" onClick={startLogout}>
            <FcCancel />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};
