import { useState } from "react";
import { Sidebar } from "../components/Sidebar";
import Content from "../components/content";

import { Navbar } from "../components/Navbar";
import { useTheme } from "../../theme/Theme";
import { Box } from "@mui/material";

export const TecRoutes = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const { theme, toggleTheme } = useTheme();
  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  return (
    <div className="app">
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
        breakPoint="xl"
        changeTheme={toggleTheme}
        theme={theme}
      />
      <main className={`content ${theme}`}>
        <Navbar c={handleToggleSidebar} />
        <Box className="content-app" sx={{ mt: [4, 4, 4, 0, 0] }}>
          <div className="content-info">
            <Content />
          </div>
        </Box>
      </main>
    </div>
  );
};
