import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import ImageTec from "../../../../../resources/imagen/logoTecnogenia.png";
import { styles } from "../../../../../styles/stylesPdf";

const PrintProduct = ({ data }) => {
  // Helper function to determine if a field should be displayed
  const shouldRenderColumn = (field) =>
    data.some((item) => item[field] !== undefined && item[field] !== null);

  // Define the columns with their respective fields and titles
  const columns = [
    { field: "productName", title: "Nombre", width: "30%" },
    { field: "nameAgency", title: "Agencia", width: "15%" },
    { field: "totalQuantity", title: "Cant", dynamic: true },
    { field: "quantityInventory", title: "Inven", dynamic: true },
    { field: "difference", title: "Stock", dynamic: true },
    { field: "quantityELALTO", title: "EA", dynamic: true },
    { field: "quantityLAPAZ", title: "LP", dynamic: true },
    { field: "quantityCOCHABAMBA", title: "CBBA", dynamic: true },
    { field: "quantitySANTACRUZ", title: "SCZ", dynamic: true },
    { field: "quantityORURO", title: "OR", dynamic: true },
    { field: "quantityPOTOSI", title: "PT", dynamic: true },
    { field: "quantityCHUQUISACA", title: "CH", dynamic: true },
    { field: "quantityTARIJA", title: "TR", dynamic: true },
    { field: "quantityBENI", title: "BE", dynamic: true },
    { field: "quantityPANDO", title: "PD", dynamic: true },
    { field: "purchasePriceBs", title: "Com", dynamic: true },
    { field: "salePrice", title: "Vent", dynamic: true },
    { field: "totalPurchasePriceBs", title: "T.Com", dynamic: true },
    { field: "totalSalePrice", title: "T.Ven", dynamic: true },
  ];

  // Filter out dynamic columns that should not be shown
  const visibleColumns = columns.filter((col) =>
    col.dynamic ? shouldRenderColumn(col.field) : true
  );

  // Calculate dynamic widths for visible columns
  const dynamicWidth = `${100 / visibleColumns.length}%`;

  return (
    <Document>
      <Page style={styles.body} size="A4" orientation="landscape">
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            borderBottom: "1px #000 solid",
          }}
        >
          <View style={{ width: "36%" }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: "21px",
              }}
            >
              <Text style={{ width: "30%", fontSize: "12px" }}>Calle</Text>
              <Text style={{ width: "70%", fontSize: "12px" }}>
                : Av. Tomas Monje G.
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text style={{ width: "30%", fontSize: "12px" }}></Text>
              <Text style={{ width: "70%", fontSize: "12px" }}>
                : Zona Bella Vista Nro. 22
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text style={{ width: "30%", fontSize: "12px" }}>Correo</Text>
              <Text style={{ width: "70%", fontSize: "12px" }}>
                : tecnogenia_rl@hotmail.com
              </Text>
            </View>
          </View>
          <View style={{ width: "36%" }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: "21px",
              }}
            >
              <Text style={{ width: "30%", fontSize: "12px" }}>Telefono</Text>
              <Text style={{ width: "70%", fontSize: "12px" }}>: 2785574</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text style={{ width: "30%", fontSize: "12px" }}>Telf.Cel</Text>
              <Text style={{ width: "70%", fontSize: "12px" }}>
                : 777266051 - 63173500
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text style={{ width: "30%", fontSize: "12px" }}>NIT</Text>
              <Text style={{ width: "70%", fontSize: "12px" }}>
                : 4890617019
              </Text>
            </View>
          </View>
          <View style={{ width: "28%" }}>
            <Image
              style={{
                marginLeft: "20%",
                width: "80%",
              }}
              src={ImageTec}
            ></Image>
          </View>
        </View>
        <View style={{ marginTop: 5 }}>
          <Text style={{ fontSize: "11px", fontFamily: "hevitas" }}>
            Lista de repuestos
          </Text>
        </View>
        <View style={{ width: "100%" }}>
          <View style={{ flexDirection: "row" }}>
            {visibleColumns.map((col, index) => (
              <View key={index} style={{ width: col.width || dynamicWidth }}>
                <Text style={styles.styleTitle}>{col.title}</Text>
              </View>
            ))}
          </View>

          {(data || []).map((value, index) => (
            <View style={{ flexDirection: "row" }} key={index}>
              {visibleColumns.map((col, subIndex) => (
                <View
                  key={subIndex}
                  style={{ width: col.width || dynamicWidth }}
                >
                  <Text style={styles.styleData}>
                    {value[col.field] !== undefined ? value[col.field] : ""}
                  </Text>
                </View>
              ))}
            </View>
          ))}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default PrintProduct;
