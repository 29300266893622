import {
  Autocomplete,
  Avatar,
  Box,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";
import React, { useMemo, useState, useCallback } from "react";
import { FcFinePrint } from "react-icons/fc";
import { useTheme } from "../../../../../theme/Theme";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useNavigate } from "react-router-dom";
import PrintProduct from "./PrintProduct";
import mainLogo from "../../../../../resources/imagen/pdf_4726010.png";
import {
  city_list,
  requestStatus,
  statusTicketFinancimiento,
} from "../../../../../resources/listData/listsData";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import tecApi from "../../../../../api/tecApi";
import { useListCompanies } from "../../../../../hooks/useListCompanies";
import ImportExcelRep from "../../../../../resources/excel/importExcelRep";

const primerDiaDelMes = moment().startOf("month").subtract(1, "month");
const fechaInicialFormateada = primerDiaDelMes.format("YYYY-MM-DD, HH:mm:ss a");
const fechaHastaHoy = moment().endOf("day");
const dayFinally = fechaHastaHoy.format("YYYY-MM-DD, HH:mm:ss a");

const TotalProduct = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();

  const [state, setState] = useState({
    isLoading: false,
    valueDateFrom: fechaInicialFormateada,
    valueDateUntil: dayFinally,
    statusFinanFilter: statusTicketFinancimiento[1],
    statusCompanyFilter: [],
    statusCityFilter: [],
    statusRequestFilter: requestStatus[1],
    reportData: [],
  });

  const listCompanies = useListCompanies();

  const callGet = useCallback(async () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const response = await tecApi.get("/reportProducts", {
        params: {
          company: state.statusCompanyFilter,
          department: state.statusCityFilter,
          statusTicket: state.statusFinanFilter,
          dateFrom: state.valueDateFrom,
          dateUntil: state.valueDateUntil,
          request: state.statusRequestFilter,
        },
      });
      setState((prevState) => ({
        ...prevState,
        reportData: response.data.finalReport,
      }));
    } catch (error) {
      console.error("Failed to fetch report data:", error);
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  }, [state]);

  const handleSelectStatusFin = (event, newValue) => {
    setState((prevState) => ({ ...prevState, statusFinanFilter: newValue }));
  };
  const handleSelectCity = (event, newValue) => {
    setState((prevState) => ({ ...prevState, statusCityFilter: newValue }));
  };
  const handleSelectRequest = (event, newValue) => {
    if (newValue === "SI") {
      setState((prevState) => ({ ...prevState, statusRequestFilter: "true" }));
    } else if (newValue === "NO") {
      setState((prevState) => ({ ...prevState, statusRequestFilter: "false" }));
    } else {
      setState((prevState) => ({ ...prevState, statusRequestFilter: null }));
    }
  };

  const handleSelectCompany = (event, newValue) => {
    setState((prevState) => ({
      ...prevState,
      statusCompanyFilter: newValue ? newValue.id : [],
    }));
  };

  const companiesMap = useMemo(() => {
    const map = {};
    (listCompanies || []).forEach((company) => {
      map[company.id] = company;
    });
    return map;
  }, [listCompanies]);

  const dataRep = useMemo(() => {
    return (state.reportData || []).map((value, index) => {
      const rowData = {
        Nro: index + 1,
        Repuesto: value.productName,
        Agencia: value.nameAgency ,
        Cantidad: value.totalQuantity,
        Inventario: value?.quantityInventory,
        Stock: value?.difference,
        "Precio Compra": value.purchasePriceBs,
        "Precio Venta": value.salePrice,
        "Total Compra": value.totalPurchasePriceBs,
        "Total Venta": value.totalSalePrice,
      };
      if (value.quantityELALTO !== undefined) {
        rowData["Cantidad El Alto"] = value.quantityELALTO;
      }
      if (value.quantityLAPAZ !== undefined) {
        rowData["Cantidad La Paz"] = value.quantityLAPAZ;
      }
      if (value.quantityCOCHABAMBA !== undefined) {
        rowData["Cantidad Cochabamba"] = value.quantityCOCHABAMBA;
      }
      if (value.quantitySANTACRUZ !== undefined) {
        rowData["Cantidad Santa Cruz"] = value.quantitySANTACRUZ;
      }
      if (value.quantityORURO !== undefined) {
        rowData["Cantidad Oruro"] = value.quantityORURO;
      }
      if (value.quantityPOTOSI !== undefined) {
        rowData["Cantidad Potosi"] = value.quantityPOTOSI;
      }
      if (value.quantityCHUQUISACA !== undefined) {
        rowData["Cantidad Chuquisaca"] = value.quantityCHUQUISACA;
      }
      if (value.quantityTARIJA !== undefined) {
        rowData["Cantidad Tarija"] = value.quantityTARIJA;
      }
      if (value.quantityBENI !== undefined) {
        rowData["Cantidad Beni"] = value.quantityBENI;
      }
      if (value.quantityPANDO !== undefined) {
        rowData["Cantidad Pando"] = value.quantityPANDO;
      }
      return rowData;
    });
  }, [state.reportData]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Box sx={{ display: "flex" }}>
          <Box>
            <Avatar
              variant="rounded"
              sx={{ width: 45, height: 45, bgcolor: "#f8f9fa" }}
            >
              <FcFinePrint />
            </Avatar>
          </Box>
          <Box className={"content-title"}>
            <div className={`titleDatagrid ${theme}`}>REPORTE DE REPUESTOS</div>
            <div className={`subTitleTable ${theme}`}>Datos de repuestos</div>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <div>
            <PDFDownloadLink
              document={<PrintProduct data={state.reportData} />}
              fileName={"Datos"}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <LinearProgress color="success" />
                ) : (
                  <img src={mainLogo} className="pdfIcon" alt="fireSpot" />
                )
              }
            </PDFDownloadLink>
          </div>
          <ImportExcelRep data={dataRep} />
        </Box>
      </Grid>
      <Grid item xs={2}>
        <button className={`buttonBack ${theme}`} onClick={() => navigate(-1)}>
          Regresar
        </button>
      </Grid>
      <Grid item xs sx={{ marginTop: 1 }}>
        <Autocomplete
          size="small"
          disablePortal
          value={
            state.statusCompanyFilter && companiesMap
              ? companiesMap[state.statusCompanyFilter]?.name
              : "" || ""
          }
          id="combo-box-companies"
          options={listCompanies}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.name;
          }}
          fullWidth
          onChange={handleSelectCompany}
          renderInput={(params) => (
            <TextField {...params} label="Entidades financieras" />
          )}
        />
      </Grid>
      <Grid item xs sx={{ marginTop: 1 }}>
        <Autocomplete
          fullWidth
          freeSolo
          forcePopupIcon={true}
          size="small"
          options={city_list}
          value={state.statusCityFilter}
          onChange={handleSelectCity}
          renderInput={(params) => <TextField {...params} label="Ciudad" />}
        />
      </Grid>
      <Grid item xs sx={{ marginTop: 1 }}>
        <Autocomplete
          fullWidth
          freeSolo
          forcePopupIcon={true}
          size="small"
          options={statusTicketFinancimiento}
          value={state.statusFinanFilter}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            return option.serie;
          }}
          onChange={handleSelectStatusFin}
          renderInput={(params) => (
            <TextField {...params} label="Estado Financiero" />
          )}
        />
      </Grid>
      <Grid item xs>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          dateLibInstance={moment}
        >
          <DemoContainer components={["DatePicker"]}>
            <DateTimePicker
              fullWidth
              label="Desde"
              ampm={false}
              value={moment(state.valueDateFrom, "YYYY-MM-DD, HH:mm:ss a")}
              onChange={(newValue) =>
                setState((prevState) => ({
                  ...prevState,
                  valueDateFrom: newValue.format("YYYY-MM-DD, HH:mm:ss a"),
                }))
              }
              slotProps={{ textField: { size: "small" } }}
              sx={{ width: "100%" }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Grid>
      <Grid item xs>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          dateLibInstance={moment}
        >
          <DemoContainer components={["DatePicker"]}>
            <DateTimePicker
              label="Hasta"
              ampm={false}
              value={moment(state.valueDateUntil, "YYYY-MM-DD, HH:mm:ss a")}
              onChange={(newValue) =>
                setState((prevState) => ({
                  ...prevState,
                  valueDateUntil: newValue.format("YYYY-MM-DD, HH:mm:ss a"),
                }))
              }
              slotProps={{ textField: { size: "small" } }}
              sx={{ width: "100%" }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Grid>
      <Grid item xs sx={{ marginTop: 1 }}>
        <Autocomplete
          fullWidth
          freeSolo
          forcePopupIcon={true}
          size="small"
          options={requestStatus}
          value={state.statusRequestFilter ? "NO" : "SI"}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            return option.serie;
          }}
          onChange={handleSelectRequest}
          renderInput={(params) => <TextField {...params} label="SOLICITUD" />}
        />
      </Grid>
      <Grid item xs>
        <button onClick={callGet} className={`buttonClass ${theme}`}>
          Buscar
        </button>
      </Grid>
      <Grid item xs={12}>
        {state.isLoading ? (
          <div className="loadingIndicator">
            <span className="loader"></span>
          </div>
        ) : (
          <PDFViewer style={{ width: "100%", height: "89vh" }}>
            <PrintProduct data={state.reportData} />
          </PDFViewer>
        )}
      </Grid>
    </Grid>
  );
};

export default TotalProduct;
