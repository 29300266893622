import {
  Autocomplete,
  Avatar,
  Box,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";
import { FcFinePrint } from "react-icons/fc";
import { useTheme } from "../../../../../theme/Theme";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PrintPrices from "./PrintPrices";
import mainLogo from "../../../../../resources/imagen/pdf_4726010.png";

import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import tecApi from "../../../../../api/tecApi";
import { model_Product } from "../../../../../resources/listData/listsData";
import ImportExcelRep from "../../../../../resources/excel/importExcelRep";
const ProductPrices = () => {
  let navigate = useNavigate();
  const { theme } = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValues] = useState([]);
  const [productFilter, setproductFilter] = useState();
  const goBack = () => {
    navigate(-1);
  };
  const callGet = () => {
    setIsLoading(true);
    tecApi
      .get("/productEntry/productPrices", {
        params: { model: productFilter },
      })
      .then((response) => {
        // console.log(response.data);
        setValues(response.data.listProductPrices);
      })
      .finally(() => {
        setIsLoading(false); // Establecer isLoading a false después de la llamada (independientemente del resultado)
      });
  };
  const handleSelectModel = (event, newValue) => {
    if (newValue === null) {
      return newValue;
    }
    setproductFilter(newValue);
  };
  const dataRep = useMemo(() => {
    return (value || []).map((value, index) => ({
      Nro: index + 1,
      Nombre: value?._id,
      "Precio Actual": value?.recentProducts[1]?.salePriceBs,
      "Precio Nuevo": value?.recentProducts[0]?.salePriceBs,
    }));
  }, [value]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <Box sx={{ display: "flex" }}>
          <Box>
            <Avatar
              variant="rounded"
              sx={{ width: 45, height: 45, bgcolor: "#f8f9fa" }}
            >
              <FcFinePrint />
            </Avatar>
          </Box>
          <Box className={"content-title"}>
            <div className={`titleDatagrid ${theme}`}>
              REPORTE DE PRECIOS PRODUCTO
            </div>
            <div className={`subTitleTable ${theme}`}>
              Datos historicos de equipo
            </div>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <div>
            <PDFDownloadLink
              document={<PrintPrices data={value} />}
              fileName={"Datos"}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <LinearProgress color="success" />
                ) : (
                  <img src={mainLogo} className="pdfIcon" alt="fireSpot" />
                )
              }
            </PDFDownloadLink>
          </div>
          <ImportExcelRep data={dataRep} />
        </Box>
      </Grid>
      <Grid item xs>
        <button className={`buttonBack ${theme}`} onClick={goBack}>
          Regresar
        </button>
      </Grid>
      <Grid item xs>
        <Autocomplete
          fullWidth
          freeSolo
          forcePopupIcon={true}
          size="small"
          options={model_Product}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            return option.name;
          }}
          onChange={handleSelectModel}
          renderInput={(params) => <TextField {...params} label="Modelo" />}
        />
      </Grid>
      <Grid item xs>
        <button onClick={callGet} className={`buttonClass ${theme}`}>
          Buscar
        </button>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <div className="loadingIndicator">
            <span className="loader"></span>
          </div>
        ) : (
          <PDFViewer style={{ width: "100%", height: "89vh" }}>
            <PrintPrices data={value} />
          </PDFViewer>
        )}
      </Grid>
    </Grid>
  );
};

export default ProductPrices;
