import { createSlice } from "@reduxjs/toolkit";
export const totalTicketSlice = createSlice({
  name: "totalTicket",
  initialState: {
    isLoadingTicket: true,
    rows: [],
    activeRows: null,
  },
  reducers: {
    onSetActiveRows: (state, { payload }) => {
      state.activeRows = payload;
    },
    onAddNewRow: (state, { payload }) => {
      state.rows.push(payload);
      state.activeRows = null;
    },
    onUpdateRow: (state, { payload }) => {
      state.rows = state.rows.map((event) => {
        if (event.id === payload.id) {
          return payload;
        }
        return event;
      });
    },
    onDeleteRow: (state) => {
      state.rows.ticketData = state.rows.ticketData.filter(
        (event) => event.id !== state.activeRows.id
      );
    },
    onLoadTicket: (state, { payload = [] }) => {
      // console.log("payload", payload);
      state.isLoadingTicket = false;
      state.rows = payload;
    },
  },
});
export const {
  onSetActiveRows,
  onAddNewRow,
  onUpdateRow,
  onDeleteRow,
  onLoadTicket,
} = totalTicketSlice.actions;
