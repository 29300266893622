import React from "react";
import mainLogo from "../../resources/imagen/excel.png";
import * as XLSX from "xlsx";

const ImportExcelRep = ({ data }) => {
  const exportToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert data to worksheet format
    const worksheet = XLSX.utils.json_to_sheet(data);
    //ajustar automaticamente el ancho de las columnas
    const columnWidths = Object.keys(data[0]).map((key) => {
      //obtener la longitud maxima de la columna
      const maxLength = Math.max(
        ...data.map((item) => (item[key] ? item[key].toString().length : 0)),
        key.length
      );
      return { width: maxLength + 2 }; // añadir un poco de espacio adicional
    });
    worksheet["!cols"] = columnWidths;
    // Append worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "data.xlsx");
  };

  return (
    <div>
      <img
        src={mainLogo}
        alt="Botón"
        className="excelIcon"
        onClick={exportToExcel}
      />
    </div>
  );
};

export default ImportExcelRep;
