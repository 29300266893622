import { createSlice } from "@reduxjs/toolkit";
export const staffSlice = createSlice({
  name: "staff",
  initialState: {
    isLoadingStaff: true,
    rowsStaff: [],
    activeRows: null,
  },
  reducers: {
    onSetActiveRows: (state, { payload }) => {
      state.activeRows = payload;
    },

    onLoadStaff: (state, { payload = [] }) => {
      state.isLoadingStaff = false;
      payload.forEach((element) => {
        const exists = state.rowsStaff.some(
          (dbStaff) => dbStaff.id === element.id
        );
        if (!exists) {
          state.rowsStaff.push(element);
        }
      });
    },
  },
});
export const { onSetActiveRows, onLoadStaff } = staffSlice.actions;
