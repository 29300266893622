import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import tecApi from "../../../../../api/tecApi";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");
const LineChartModel = ({ company }) => {
  const [values, setValues] = useState([]);
  useEffect(() => {
    tecApi
      .get("/reportProducts/reportProdutsModel", {
        params: { company: company },
      })
      .then((response) => {
        setValues(response.data.countProducts);
      });
  }, []);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  const currentDate = moment();
  const previousTowMonthDate = currentDate
    .clone()
    .subtract(2, "month")
    .format("MMMM");
  const previousMonthDate = currentDate
    .clone()
    .subtract(1, "month")
    .format("MMMM");
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "white", // Cambia el color de los labels aquí
        },
      },

      title: {
        display: true,
        text: "CANTIDAD DE REPUESTOS POR MODELO",
        color: "white",
      },
    },
    scales: {
      x: {
        ticks: {
          color: "white",
          font: {
            size: 16,
          },
        },
      },
      y: {
        ticks: {
          color: "white",
          font: {
            size: 16,
          },
        },
      },
    },
  };
  const labels = [
    "NC7100",
    "NC6500",
    "NC3500",
    "GFS220",
    "VC2600",
    "N-120",
    "VC3600",
    "VC16M-220",
  ];
  const mappedDataFirstMonth = labels.map((dep) => {
    const dataPoint = (values.firstMonth || []).find(
      (data) => data._id === dep
    );
    return dataPoint ? dataPoint.count : 0;
  });
  const mappedDataSecondMonth = labels.map((dep) => {
    const dataPoint = (values.secondMonth || []).find(
      (data) => data._id === dep
    );
    return dataPoint ? dataPoint.count : 0;
  });
  const data = {
    labels,
    datasets: [
      {
        label: previousTowMonthDate,
        data: mappedDataFirstMonth,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: previousMonthDate,
        data: mappedDataSecondMonth,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return (
    <div>
      <Line options={options} data={data} />
    </div>
  );
};

export default LineChartModel;
