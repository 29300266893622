import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import { styles } from "../../../../../styles/stylesPdf";
import ImageTec from "../../../../../resources/imagen/logoTecnogenia.png";
const PrintPrices = ({ data }) => {
  return (
    <Document>
      <Page style={styles.body} size="A4" orientation="landscape">
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            borderBottom: "1px #000 solid",
          }}
        >
          <View style={{ width: "36%" }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: "21px",
              }}
            >
              <Text style={{ width: "30%", fontSize: "12px" }}>Calle</Text>
              <Text style={{ width: "70%", fontSize: "12px" }}>
                : Av. Tomas Monje G.
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text style={{ width: "30%", fontSize: "12px" }}></Text>
              <Text style={{ width: "70%", fontSize: "12px" }}>
                : Zona Bella Vista Nro. 22
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text style={{ width: "30%", fontSize: "12px" }}>Correo</Text>
              <Text style={{ width: "70%", fontSize: "12px" }}>
                : tecnogenia_rl@hotmail.com
              </Text>
            </View>
          </View>
          <View style={{ width: "36%" }}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: "21px",
              }}
            >
              <Text style={{ width: "30%", fontSize: "12px" }}>Telefono</Text>
              <Text style={{ width: "70%", fontSize: "12px" }}>: 2785574</Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text style={{ width: "30%", fontSize: "12px" }}>Telf.Cel</Text>
              <Text style={{ width: "70%", fontSize: "12px" }}>
                : 777266051 - 63173500
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Text style={{ width: "30%", fontSize: "12px" }}>NIT</Text>
              <Text style={{ width: "70%", fontSize: "12px" }}>
                : 4890617019
              </Text>
            </View>
          </View>
          <View style={{ width: "28%" }}>
            <Image
              style={{
                marginLeft: "20%",
                width: "80%",
              }}
              src={ImageTec}
            ></Image>
          </View>
        </View>
        <View style={{ marginTop: 5 }}>
          <Text
            style={{
              fontSize: "11px",
              fontFamily: "hevitas",
            }}
          >
            PRECIOS REGISTRADOS
          </Text>
        </View>
        <View style={{ width: "100%" }}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "40%" }}>
              <Text style={styles.styleTitle}>Nombre</Text>
            </View>
            <View style={{ width: "40%" }}>
              <Text style={styles.styleTitle}>Precio Actual</Text>
            </View>
            <View style={{ width: "40%" }}>
              <Text style={styles.styleTitle}>Precio Nuevo</Text>
            </View>
          </View>
          {(data || []).map((value, index) => (
            <View style={{ flexDirection: "row" }} key={index}>
              <View style={{ width: "40%" }}>
                <Text style={styles.styleData}>{value?._id}</Text>
              </View>
              <View style={{ width: "40%" }}>
                <Text style={styles.styleData}>
                  {value?.recentProducts[1]?.salePriceBs
                    ? (value?.recentProducts[1]?.salePriceBs).toFixed(2)
                    : 0}
                </Text>
              </View>
              <View style={{ width: "40%" }}>
                <Text style={styles.styleData}>
                  {value?.recentProducts[0]?.salePriceBs
                    ? (value?.recentProducts[0]?.salePriceBs).toFixed(2)
                    : 0}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default PrintPrices;
