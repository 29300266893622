import React from "react";
import { Avatar, Grid, MenuItem } from "@mui/material";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import userIcon from "../../resources/imagen/user.jpg";

export const CustomSidebarHeader = ({
  collapsed,
  handleCollapsedChange,
  imagenUser,
  user,
}) => {
  const collapseIcon = collapsed ? (
    <FaAngleDoubleRight />
  ) : (
    <FaAngleDoubleLeft />
  );

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: "10px 0", backgroundColor: "var(--green)" }}
      >
        <Grid item>
          {!collapsed && <label className="title-sidebar">TECNOGENIA</label>}
        </Grid>
        <Grid item>
          <MenuItem
            onClick={handleCollapsedChange}
            style={{ justifyContent: "center" }}
          >
            {collapseIcon}
          </MenuItem>
        </Grid>
      </Grid>
      {!collapsed && (
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{ padding: "10px" }}
        >
          <Grid item>
            <Avatar
              alt="User"
              src={imagenUser?.imagenUser || userIcon}
              sx={{ width: 50, height: 50 }}
            />
          </Grid>
          <Grid item>
            <div>
              <span className="name-user-sidebar">
                {user.name} {user.lastname}
              </span>
            </div>
            <div className="job-user-title">
              <span className="user-rol">{user.jobTitle}</span>
              <span className="user-rol">{imagenUser.code}</span>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};
