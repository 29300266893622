import { createSlice } from "@reduxjs/toolkit";
export const ticketClientSlice = createSlice({
  name: "ticketClient",
  initialState: {
    isLoadingTicket: true,
    rowsClient: [],
    activeRows: null,
  },
  reducers: {
    onSetActiveRows: (state, { payload }) => {
      state.activeRows = payload;
    },
    onLoadTicket: (state, { payload = [] }) => {
      state.isLoadingTicket = false;

      state.rowsClient = payload;
    },
  },
});
export const { onSetActiveRows, onLoadTicket } = ticketClientSlice.actions;
