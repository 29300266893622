import { Font, StyleSheet } from "@react-pdf/renderer";
import fontF from "../resources/founts/ARIBL0.ttf";

Font.register({
  family: "hevitas",
  fonts: [
    {
      src: fontF,
    },
  ],
});
Font.register({
  family: "Courier",
  fonts: [
    {
      src: fontF,
    },
  ],
});
export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 30,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "hevitas",
  },
  header: {
    fontSize: 12,
    marginBottom: 17,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  styleTitle: {
    fontSize: "7px",
    textAlign: "center",
    fontFamily: "hevitas",
    border: "1px solid #000",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  styleData: {
    fontSize: "7px",
    textAlign: "center",
    fontFamily: "Courier",
    border: "1px solid #000",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  styleDataName: {
    fontSize: "7px",
    textAlign: "left",
    fontFamily: "Courier",
    border: "1px solid #000",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  containerHeader: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
  },
  sectionHeader: {
    width: "36%",
    paddingHorizontal: 20,
  },
  rowHeader: {
    flexDirection: "row",
    width: "100%",
  },
  labelHeader: {
    width: "30%",
    fontSize: 12,
  },
  infoHeader: {
    width: "70%",
    fontSize: 12,
  },
  imageHeader: {
    width: 200,
    height: 33,
  },
  containerInfo: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 5,
  },
  sectionInfo: {
    width: "70%",
  },
  dateSectionInfo: {
    width: "30%",
  },
  labelInfo: {
    width: "50%",
    fontSize: 10,
  },
  valueInfo: {
    width: "50%",
    fontSize: 10,
  },
  styleDataToal: {
    fontSize: "7px",
    textAlign: "right",
    fontFamily: "hevitas",
    border: "1px solid #000",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  styleDataBS: {
    fontSize: "7px",
    textAlign: "right",
    fontFamily: "Courier",
    border: "1px solid #000",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },

  titleEntry: {
    fontSize: 14,
    textAlign: "center",
    textTransform: "uppercase",
    textDecoration: "underline",
    marginTop: "10px",
    fontFamily: "hevitas",
  },
  titleTable: {
    textAlign: "center",
    fontSize: 10,
    textTransform: "uppercase",
    fontFamily: "hevitas",
  },
  contenteTable: {
    fontSize: "9px",
    textAlign: "center",
    fontFamily: "Courier",
    border: "1px solid #000",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  titleTableDetails: {
    fontSize: 12,
    textTransform: "uppercase",
    fontFamily: "hevitas",
  },
  titleTableContent: {
    textAlign: "center",
    fontSize: 12,
    textTransform: "uppercase",
    fontFamily: "Courier",
  },

  dataContent: {
    fontSize: "10px",
    marginTop: "2px",
    textAlign: "center",
    fontFamily: "Courier",
  },
  dataContentTitle: {
    fontSize: "10px",
    textAlign: "center",
    fontFamily: "hevitas",
  },
  dataContentTableTitle: {
    fontSize: "10px",
    border: "1px solid #000",
    textAlign: "left",
    fontFamily: "hevitas",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  dataContentTableTitleRigth: {
    fontSize: "10px",
    border: "1px solid #000",
    textAlign: "right",
    fontFamily: "hevitas",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  dataContentTableleft: {
    fontSize: "10px",
    border: "1px solid #000",
    textAlign: "left",
    fontFamily: "Courier",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  dataContentTableRight: {
    fontSize: "10px",
    border: "1px solid #000",
    textAlign: "right",
    fontFamily: "Courier",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },

  styleDataTotal: {
    fontSize: "7px",
    textAlign: "left",
    fontFamily: "Courier",
    border: "1px solid #000",
    padding: "2px",
    whiteSpace: "nowrap", // Prevent text wrapping
  },
  //STYLES TABLE
  tableContainer: {
    marginTop: 10,
    width: "100%",
  },
  tableHeader: {
    flexDirection: "row",
    backgroundColor: "#f5f5f5",
  },
  tableHeaderText: {
    fontSize: 9,
    paddingTop: 4,
    paddingBottom: 4,
    fontFamily: "Courier",
    textAlign: "center",
    border: "0.5px solid #000",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 7,
    textAlign: "center",
    border: "0.5px solid #000",
  },
  tableFooter: {
    flexDirection: "row",
    marginTop: 5,
  },
  totalCell: {
    fontSize: 9,
    fontWeight: "bold",
    textAlign: "center",
    padding: 4,
    border: "1px solid #000",
  },
  totalLabel: {
    fontSize: 7,
    padding: 4,
    fontFamily: "hevitas",
    border: "0.5px solid #000",
    textAlign: "center",
  },
  totalLabelMantenimientos: {
    fontSize: 7,
    padding: 4,
    fontFamily: "hevitas",
    border: "0.5px solid #000",
    textAlign: "center",
  },
  totalAmount: {
    fontSize: 7,
    fontWeight: "semibold",
    width: "8%",
    textAlign: "center",
  },
  totalAmountReport: {
    fontSize: 7,
    textAlign: "right",
    padding: 4,
    fontFamily: "hevitas",
  },
  totalAmountReportProduct: {
    fontSize: 7,
    textAlign: "right",
    paddingRight: 8,
    fontFamily: "hevitas",
  },
});
