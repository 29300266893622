import { createSlice } from "@reduxjs/toolkit";
// const tempRow = { id: 1, name: 1, code: "Snow", entityType: "Jon" };
export const agencySlice = createSlice({
  name: "agency",
  initialState: {
    isLoadingAgency: true,
    rowsAgency: { agencyList: [], activeRows: null },
  },
  reducers: {
    onSetActiveRows: (state, { payload }) => {
      state.activeRows = payload;
    },
    onAddNewRow: (state, { payload }) => {
      state.rowsAgency.agencyList.push(payload);
      state.rowsAgency.activeRows = null;
    },
    onUpdateRow: (state, { payload }) => {
      state.rowsAgency.agencyList = state.rowsAgency.agencyList.map((event) => {
        if (event.id === payload.id) {
          return payload;
        }
        return event;
      });
    },
    onDeleteRow: (state) => {
      if (state.activeRows) {
        state.rowsAgency.agencyList = state.rowsAgency.agencyList.filter(
          (event) => event.id !== state.activeRows.id
        );
        state.rowsAgency.activeRows = null;
      }
    },
    onLoadAgency: (state, { payload = [] }) => {
      state.isLoadingAgency = false;
      state.rowsAgency = payload;
    },
  },
});
export const {
  onSetActiveRows,
  onAddNewRow,
  onUpdateRow,
  onDeleteRow,
  onLoadAgency,
} = agencySlice.actions;
