import { createSlice } from "@reduxjs/toolkit";
export const productListSlice = createSlice({
  name: "productList",
  initialState: {
    isLoadingProduct: true,
    rowProductList: { products: [], activeRows: null },
  },
  reducers: {
    onSetActiveRows: (state, { payload }) => {
      state.activeRows = payload;
    },
    onAddRow: (state, { payload }) => {
      state.rowProductList.products.push(payload);
      state.activeRows = null;
    },
    onUpdateRow: (state, { payload }) => {
      state.rowProductList.products = state.rowProductList.products.map(
        (event) => {
          if (event.id === payload.id) {
            return payload;
          }
          return event;
        }
      );
    },
    onDeleteRow: (state) => {
      if (state.activeRows) {
        state.rowProductList.product = state.rowProductList.product.filter(
          (event) => event.id !== state.activeRows.id
        );
        state.activeRows = null;
      }
    },
    onLoadProduct: (state, { payload = [] }) => {
      state.isLoadingProduct = false;

      state.rowProductList = payload;
    },
  },
});
export const {
  onSetActiveRows,
  onAddRow,
  onUpdateRow,
  onDeleteRow,
  onLoadProduct,
} = productListSlice.actions;
