import { FaBars } from "react-icons/fa";
import { useTheme } from "../../theme/Theme";
import { Box } from "@mui/material";

export const Navbar = ({ c }) => {
  const { theme } = useTheme();
  const handleToggleSidebar = (value) => {
    c(value);
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          display: { xs: "flex", lg: "none" },
          padding: 2,
        }}
      >
        <Box>
          <FaBars
            className={`icon-navbar ${theme}`}
            onClick={() => handleToggleSidebar(true)}
          />
        </Box>
      </Box>
    </>
  );
};
